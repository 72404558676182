import type { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

interface CardProps {
  bordered?: boolean
}

const Container = styled.div<CardProps>(
  ({ bordered, theme: { borderStyle, borderWidth, colors, spacing } }) => css`
    background-color: ${colors.neutral.lightest};
    display: flex;
    flex: 1;
    flex-direction: column;

    ${bordered &&
    css`
      border: ${borderWidth.main} ${borderStyle.solid} ${colors.neutral.light};
      padding: ${spacing.lg} ${spacing.md};
    `}
  `,
)

const Card = ({
  bordered,
  children,
  ...props
}: PropsWithChildren<CardProps>) => (
  <Container bordered={bordered} {...props}>
    {children}
  </Container>
)

export default styled(Card)``
